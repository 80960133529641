// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import { ready } from "../helpers";

require("trix")
require("@rails/actiontext")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

NodeList.prototype.forEach = Array.prototype.forEach;
NodeList.prototype.map = Array.prototype.map;
NodeList.prototype.reduce = Array.prototype.reduce;

window.UIkit = require("uikit/dist/js/uikit")
require("uikit/dist/js/uikit-icons")

window.App = window.App || {};
window.App.appendScript = function (text) {
  // shamelessly stolen from rails-ujs
  var script = document.createElement("script");
  script.setAttribute("nonce", Rails.cspNonce());
  script.text = text;
  document.head.appendChild(script).parentNode.removeChild(script);
};
window.App.showFlash = function () {
  document.querySelectorAll("#flash-wrapper").forEach((el) => {
    el.querySelectorAll("i").forEach((el) => {
      UIkit.notification(el.innerHTML, {
        status: el.getAttribute("class"),
        //pos: "top-right",
        timeout: "3000",
      });
    });
  });
};
window.App.setFlash = function (content) {
  var wrapper = document.querySelector("#flash-wrapper");
  if (wrapper) {
    wrapper.innerHTML = content;
  }
  App.showFlash();
  return wrapper;
};


ready(function() {
  App.showFlash();
});

import "controllers"
